sbmn-flights-results {
  position: relative;
  display: block;
  > header {
    position: relative;
    display: flex;
    padding: 0;
    width: 100%;
    align-items: center;
    z-index: z(level2);
    > .max-width-box {
      max-width: 100%;
    }

    &.hsummary {
      height: 65px;
      max-height: 65px;
      background: $Bgrey2;
      @include mq('tablet', 'max') {
        margin-top: 60px;
      }
    }

    &.hhide {
      background: $Bwhite;
    }
  }

  sbmn-flights-search-engine {
    max-width: 1440px;
    margin: auto;
    @include mq('tablet', 'max') {
      margin-top: 83px;
    }
  }

  @include mq('tablet', 'min') {
    //------------------header FIX
    header.sticky {
      position: fixed;
      top: 0;
      height: 50px;
      animation: headerIn 1s forwards;
      box-shadow: 0 8px 12px -6px rgba($Bblack, 0.5);
      z-index: z(level3);

      &.hhide {
        height: auto;
      }
    }
    .sticky:not(.hhide) {
      #searchEngineForm {
        padding-top: 0;
      }

      #nav_search-engine .selected {
        @include circle(30px);
        display: flex;
        border-width: 2px;
      }

      #searchEngineForm,
      #nav_search-engine,
      #destdates_search-engine,
      #passengers_search-engine,
      .mat-form-field,
      .menosopciones {
        height: 50px;
      }

      #passengers_search-engine .personas {
        border: 0;
      }

      .mat-form-field-label {
        top: 1.7em;
      }
    }
    #fixstickysengine {
      height: 65px;
    }
  }

  i {
    //@include circle(40px);
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: $Bwhite;
    //border: 1px solid $Bgrey3;
    font-style: normal;
    @include mq('desktop', 'max') {
      //@include circle(30px);
      //display: flex;
      margin: 3px 6px 3px 0;
    }

    &.selected {
      border-color: $Bprimary-lighter;
      background: rgba($Bsecondary, 0.2);
    }
  }

  .filtrar {
    color: $Bgrey3;
    border-bottom: 2px solid $Bgrey3;
    background: $Btransparent;
    cursor: pointer;
    margin: 0 20px 0 0;
    font-size: 3rem;
    padding-bottom: 5px;

    &:hover,
    &:focus {
      color: $Bsecondary;
      border-color: $Bsecondary;
    }

    @include mq('desktop', 'max') {
      font-size: 2rem;
    }
  }

  .menosopciones {
    &:before {
      font-size: 3rem;
      color: $Bthird;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }

    opacity: 0.5;
    transform: rotateX(180deg);
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 15px;
    align-items: center;
    cursor: pointer;
    transition:
      transform 0.2s linear,
      opacity 0.2s linear;

    &.expanded {
      transform: rotateX(0);
    }
  }

  .leftdialog,
  .leftdialog.hide,
  .leftdialog.show {
    @include mq('tablet', 'max') {
      left: 0;
      top: 60px;
      width: 100%;
      padding-right: 0;
      padding-bottom: 0;
      max-width: unset;
      min-width: unset;
      &__list {
        padding-left: 5px;

        li {
          text-decoration: none;
        }
      }
    }
  }

  .leftdialog.hide {
    @include mq('tablet', 'max') {
      left: -100%;
      width: 100%;
    }
  }

  sbmn-pagination {
    width: auto;
  }

  .cssload-loader__container {
    position: absolute;
    z-index: z(level1);
    padding-top: 10%;
  }
}

.results_controls {
  padding: 0 1%;
  height: 100%;
  z-index: z(level5);
  display: flex;
  align-items: center;
  cursor: pointer;
  @include mq('tablet', 'max') {
    align-items: flex-start;
    margin-top: 15px;
    &:first-child {
      margin-top: 8px;
    }
  }
}

#fresults {
  display: flex;
  justify-content: center;
  max-width: 100%;
  min-height: 1300px;
  overflow: unset;
}

#fresults_list {
  width: 100%;
  max-width: 1280px;
  padding: 0 3%;
  @include mq('tablet', 'max') {
    padding: 0;
  }
}

.fresult_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 4rem;
    margin: 20px 0;
    position: relative;
    display: flex;
    align-items: center;
  }

  @include mq('tablet', 'max') {
    padding: 0;
    flex-direction: column;
    h1 {
      text-align: center;
      margin: 15px 0 0;
    }
  }
}

.fresult_scheme {
  margin: 0 0 40px 0;
  display: flex;
  box-shadow: -3px -3px 10px -3px rgba($Bblack, 0.2);
  font-size: 1.6rem;
  min-height: 234px;
  background: rgba($Bgrey05, 0.7);
  @include mq('tablet', 'max') {
    flex-direction: column;
    box-shadow: 0px 2px 7px 5px rgba($Bblack, 0.2);
  }
}

.fresult_schemedata {
  width: 100%;
  box-shadow: 5px 5px 10px -2px rgba($Bblack, 0.2);
  z-index: z(floor);
  //&:before{
  //  content: '';
  //  @extend .Bborder-blr;
  //}
  //
  //&._Simple_,
  //&._Multidestino_ {
  //  @include mq('tablet', 'max') {
  //    border-left-width: 8px;
  //    & + .fresult_schemeprice {
  //      border-left: 8px solid $Bgrey2;
  //    }
  //  }
  //}
  //
  //&._Simple_.selected_scheme {
  //  border-color: $Bprimary-lighter;
  //  @include mq('tablet', 'max') {
  //    & + .fresult_schemeprice {
  //      border-color: $Bprimary-lighter;
  //    }
  //  }
  //}
  //
  //&._Multidestino_.selected_scheme {
  //  border-color: $Bprimary;
  //  @include mq('tablet', 'max') {
  //    & + .fresult_schemeprice {
  //      border-color: $Bprimary;
  //    }
  //  }
  //}

  /* &._Calendario_.selected_scheme{
   border-color: $Bprimary-dark;
 }*/
}

.fresult_headerscheme {
  display: flex;
  padding: 5px;
  align-items: center;
  background: $Bgrey05;
  border-bottom: 1px solid $Bgrey4;

  i {
    transform: rotateX(0);
    width: auto;
    min-width: 98px;
    border-right: 2px solid $Bgrey3;
    padding-right: 15px;
    justify-content: left;
    &.multiida {
      font-size: 0;
      color: $Btransparent;
    }
    &:before {
      font-family: basicbold, Arial, sans-serif !important;
      font-size: 2rem;
      content: attr(title);
      text-transform: uppercase;
      color: $Bgrey8;
    }
  }

  p {
    margin: 5px;
    color: $Bprimary-lighter;
    font-family: basicbold, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1;

    span {
      color: $Bblack;
      font-family: basicregular, Arial, sans-serif;
      text-transform: uppercase;
      font-size: 1.4rem;
    }
  }
}

.fresult_route {
  position: relative;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid $Bgrey2;
  transition: background 0.1s linear;
  background: $Bwhite;
  min-height: 60px;
  cursor: pointer;
  width: 100%;

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &:focus,
  &.selected {
    background: $Bsecondary;

    .fresults_schemeicons i {
      color: $Bgrey4;
    }

    .fresults_schemeicons i.butaca {
      color: $Bgrey8;
    }

    .fresults_schemeicons i:before {
      color: $Bgrey4;
      border-color: $Bgrey4;
    }

    .fresults_schemeicons i.butaca:before {
      color: rgba($Bsecondary-light, 0.8);
    }
  }

  &:hover,
  &:focus {
    .fresults_state {
      background: rgba($Bwhite, 0.9);
    }
  }

  &.selected {
    .fresults_state {
      background: rgba($Bwhite, 0.8);
    }

    .fresults_routesummary {
      color: $Bprimary-dark;
      font-size: 2rem;
      line-height: 0.9;
    }
  }

  &.disabled {
    opacity: 0.3;
  }

  &.selected.disabled {
    opacity: 0.3;
    background: $Bwhite;
  }

  &.extra {
    &:hover,
    &:focus,
    &.selected {
      background: $Bthird;
      .fresults_state {
        background: rgba($Bwhite, 0.9);
      }
    }
  }

  &:before {
    position: absolute;
    right: 15px;
    top: 20px;
    color: $Btransparent;
    font-size: 2rem;
    @include mq('tablet', 'max') {
      right: 2px;
      font-size: 1.8rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.selected:before {
    color: white;
  }

  figure {
    margin: 0;
    padding: 0;
    z-index: z(level2);
  }
}

.fresults_selector {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: z(level2);
}

.fresults_state {
  // margin:2px 60px 2px 2px;
  margin: 0 60px 0 0;
  padding: 0 20px;
  background: $Bwhite;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 70px;
  @include mq('tablet', 'max') {
    margin: 0 30px 0 0;
    padding: 0 10px;
    figure {
      position: absolute;
      top: 5px;
    }
  }

  img {
    max-height: 50px;
  }
}

.fresults_routetext {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  @include mq('tablet', 'max') {
    flex-wrap: wrap;
    padding: 10px 0;
  }

  i {
    @include mq('desktop', 'max') {
      @include circle(40px);
    }
  }
}

.fresults_routesummary {
  width: 100%;
  padding: 5px 30px;
  font-family: basicregular, Arial, sans-serif;
  color: $Bblue-lighter;
  font-size: 2.5rem;
  line-height: 1;
  align-items: center;
  @include mq('tablet', 'max') {
    padding: 5px 15px 10px;
    flex-wrap: wrap;
    margin-left: 50px;
  }
  br {
    display: none;
  }
  span {
    color: $Bblack;
    font-family: basicregular, Arial, sans-serif;
    text-transform: uppercase;
    font-size: 1.3rem;
    line-height: 1;
    display: block;
  }
}

.fresults_stopover {
  min-width: 120px;
  padding: 0 5px 0 15px;
  text-align: center;
  font-size: 1.3rem;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq('tablet', 'max') {
    min-width: unset;
    padding: 0 5px;
  }

  span:first-child {
    text-decoration: underline;
  }

  span.isCupo {
    background-color: $Bgrey1;
    padding: 0.15rem 1.4rem;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    text-decoration: none !important;
    max-width: fit-content;
    margin-top: 2px;
    font-size: x-small;
  }


  .escala {
    color: $Bgrey8;
    position: relative;
    background: $Btransparent;
    display: flex;
    flex-direction: row-reverse;
    margin: 0;

    &:before {
      font-size: 3.5rem;
      color: $Bgrey2;
    }
  }

  .pt {
    font-family: basicregular, Arial, sans-serif;
    color: $Bviolet-dark;
    margin: 0 0 0 35px;
    min-width: 25px;
    display: inline-block;
  }
}

.fresults_schemeicons {
  width: 180px;
  display: flex;
  justify-content: space-between;
  @include mq('tablet', 'max') {
    flex-wrap: wrap;
    width: unset;
    height: auto;
    padding: 0;
  }

  i {
    @include circle(45px);
    display: flex;
  }

  i:before {
    color: $Bgrey3;
    font-size: 3rem;
    position: absolute;
  }

  .butaca {
    position: relative;

    &:before {
      color: $Bgrey1;
      font-size: 3rem;
      top: 6px;
      left: 6px;
      z-index: z(floor);
    }

    @include mq('tablet', 'max') {
      padding: 10px;
      &:before {
        top: 7px;
        left: 8px;
      }
    }

    .fresults_seats_text {
      z-index: z(level1);
      color: $Bgrey6;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-family: basicregular, Arial, sans-serif;
    }
  }

  .maleta {
    display: flex;

    &:before {
      font-size: 3.3rem;
      margin-top: -1px;
    }
  }

  .fresults_baggagetxt {
    color: $Bwhite;
    z-index: z(level1);
    font-weight: bold;
    padding-top: 3px;
    font-size: 1.4rem;

    small {
      font-size: 1.2rem;
      font-weight: normal;
    }

    @include mq('tablet', 'max') {
      font-size: 1.25rem;
      text-align: center;
    }
  }

  .clase {
    color: $Bgrey3;
    font-weight: bold;
    @include mq('tablet', 'max') {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.fresult_schemeprice {
  min-width: 180px;
  padding: 20px 12px;
  z-index: z(floor);
  background: $Bwhite;
  box-shadow: 7px 5px 10px -2px rgba($Bblack, 0.2);
  border-left: 1px solid $Bgrey1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.--bigscheme {
    align-items: flex-start;
  }

  @include mq('tablet', 'max') {
    box-shadow: none;
    width: 100%;
    padding: 0;
    margin-left: 0;
    flex-wrap: wrap;
    height: auto;
    border-top: 1px solid $Bgrey3;
  }

  button {
    @include mq('tablet', 'max') {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0 0 20px;
      padding: 25px 0;
      font-size: 2rem;
      border-radius: 0;
    }
    @include mq('phablet', 'max') {
      margin: 0;
    }
  }
}

.fresult_schemeprice_container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  padding: 30px 0 60px;

  .--bigscheme & {
    position: sticky;
    top: 60px;
    @include mq('tablet', 'min') {
      padding: 10px 0;
    }
  }

  @include mq('tablet', 'max') {
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    > * {
      min-width: 50%;
      text-align: center;
      padding: 0;
    }
  }
  @include mq('phablet', 'max') {
    width: 100%;
  }

  .fresults_routeinfo {
    background: $Btransparent;
    width: auto;
    height: auto;
    margin-bottom: 10px;
    &:before {
      display: none;
    }
    &:hover,
    &:focus {
      background: $Btransparent;
    }
    &.enabled {
      i {
        opacity: 0.6;
      }
      &:hover,
      &:focus {
        background: $Btransparent;
        i {
          transform: none;
        }
      }
    }
    @include mq('tablet', 'max') {
      align-items: center;
      margin: 0 0 10px;
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
    }

    i {
      margin: 0 6px;
      &:hover,
      &:focus {
        opacity: 1;
        &:before {
          transform: scale(1.5);
        }
      }
      @include mq('desktop', 'max') {
        @include circle(40px);
        display: flex;
      }
    }

    .personas {
      font-size: 1.6rem;
      font-weight: normal;
    }
  }
}

.fresults_rate {
  @include mq('tablet', 'max') {
    font-size: 1.6rem;
  }
  strong {
    span.isCupo {
      font-weight: 900;
    }
  }
}

.fresults_routeinfo {
  @include circle(50px);
  border-radius: 50%;
  background: $Bgrey05;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: z(level3);
  position: relative;
  transition: all 0.2s linear;
  &:hover,
  &:focus {
    background: $Bsecondary-light;
    color: $Bwhite;
  }
  @include mq('tablet', 'max') {
    padding: 0;
    height: auto;
    margin: 0;
    background: none;
  }

  i {
    font-size: 2.2rem;
    font-weight: bold;
    color: $Bgrey2;
    border-color: $Bgrey2;
    display: flex;

    &:before {
      transition: transform 0.2s linear;
    }
    &.selected {
      background: $Btransparent;
      &:before {
        color: $Bsecondary;
      }
    }
  }

  .block_routerinfo {
    @include cover();
  }

  &.enabled {
    &:hover,
    &:focus {
      i,
      &:before {
        transform: scale(1.2);
      }
    }
    &:before {
      content: '+';
      font-family: basicbold, Arial, sans-serif;
      margin-right: -5px;
    }
    .block_routerinfo {
      display: none;
    }

    i {
      color: $Bprimary;
      cursor: pointer;
    }
  }
}

.fresults_price {
  font-size: 3rem;
  margin: 10px auto;

  small {
    font-size: 0.8em;
  }

  @include mq('tablet', 'max') {
    font-size: 3.5rem;
  }
}

.fresult_footer {
  display: flex;
  justify-content: flex-end;
}

#fresults_ads {
  width: 230px;
  padding: 78px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  .h2withbold {
    padding-right: 10px;
    font-size: 2.5rem;
  }

  figure {
    margin: 10px 0;
  }

  @include mq('desktop', 'max') {
    display: none;
  }
}

.fresults_filters {
  position: absolute;
  min-height: calc(100% - 65px);
  background-color: $Bgrey05;
  border-right: 1px solid $Bgrey7;
  min-width: 400px;
  width: 400px;
  transition: margin-left 0.5s;
  padding: 10px 20px 50px 20px;
  margin-left: -400px;
  z-index: z(level2);
  left: 0;
  @include mq('tablet', 'max') {
    min-width: 100%;
    width: 100%;
  }

  .ko:hover {
    color: $Bblack;
  }

  &.hide {
    margin-left: -400px;
    @include mq('tablet', 'max') {
      // margin-left: -110%;
    }
  }

  &.show {
    margin-left: 0;
  }
}

.tooltipinfo {
  background-color: #fff;
  font-size: 1.5rem;
}
