sbmn-mta-cards {
  display: block;
  width: 100%;
  min-height: 500px;
  padding-bottom: 100px;

  table.table_responsive thead th {
    cursor: default;
  }

  .basic__table {
    &-paginator {
      width: 100%;
    }

    &__head {
      tr {
        border: 0;
      }
    }

    &__body {
      background: $Bwhite;

      tr {
        cursor: default;

        &:hover,
        &:focus {
          background: rgba($Berror-light, 0.2);
          border-left: 10px solid $Berror-light;
          color: $Bblack;
        }

        &.manage:hover,
        &.manage:focus {
          background: rgba($Bgreen-light, 0.2);
          border-left: 10px solid $Bgreen-light;
        }

        &.default {
          background: rgba($Bsecondary-light, 0.2);
          border-left: 10px solid $Bsecondary-light;

          td {
            font-weight: bold;
            font-size: 1.1em;

            &:last-child {
              span {
                margin-top: 11px;
              }
            }

            img {
              max-width: 52px;
            }
          }

          @include mq('tablet', 'max') {
            td {
              line-height: 30px;

              &:last-child {
                span {
                  margin-top: 5px;
                }
              }
            }
          }
        }

        td {
          height: 40px;
          position: relative;
          line-height: 1;
        }
      }

      tr td:last-child {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px 0 0;

        &:before {
          content: '';
          display: none;
        }

        @include mq('tablet', 'max') {
          justify-content: flex-end;
          span {
            margin-left: 20px;
            height: auto;
            width: auto;

            &:nth-of-type(1) {
              order: 1;
            }

            &:nth-of-type(2) {
              order: 3;
            }

            &:nth-of-type(3) {
              order: 2;
            }

            &:nth-of-type(4) {
              order: 4;
            }
          }
        }
      }

      &__card {
        max-width: 40px;
        display: flex;
        margin: auto;
        @include mq('tablet', 'max') {
          margin: 0;
        }
      }

      &__legend {
        word-break: normal;
        display: inline-block;
        overflow: hidden;
        font-size: 1.1rem;
        color: $Bgrey2;
        transition: all 0.2s linear;
        width: 0;
        min-width: 0;
        height: 0;

        &._show {
          width: auto;
          height: auto;
          color: $Bblack;
        }

        &._hide {
        }
      }

      &__action {
        margin-top: 0;
        margin-left: 5px;
        cursor: pointer;
        @include mq('tablet', 'max') {
          margin-top: 5px;
        }

        i {
          @include circle(30px);
          border: 2px solid $Bgrey3;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $Bgrey3;
          transition: all 0.2s linear;

          &:hover,
          &:focus {
            color: $Bwhite;
            background: $Bgreen-light;
            border: 2px solid $Bgreen-light;
          }

          &.delete:hover,
          &.delete:focus {
            color: $Bwhite;
            background: $Berror-light;
            border: 2px solid $Berror-light;
          }
        }
      }

      &__cross {
        &.ok:hover {
          color: $Bgreen-light;
        }

        &.ko:hover,
        &.ko:focus {
          cursor: auto;
          color: $Berror-dark;
        }

        &.webcam {
          font-size: 2rem;
          color: $Bsecondary;

          &:hover,
          &:focus {
            cursor: pointer;
            color: $Borange-red;
          }
        }
      }
    }
  }
}

.mtacards {
  &__container {
    position: relative;
    min-height: 400px;
    max-width: 1440px;
    margin: auto;

    &__saved {
      @include cover();
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background: rgba($Bwhite, 0.8);
      animation: blink 2s linear forwards;

      h3 {
        margin-top: 5%;
        color: $Bprimary-lighter;
      }
    }
  }
}

.cards__modal {
  text-align: center;

  .stopper-modal__scroll__container__title {
    color: $Bwhite;
    font-size: 4rem;
    font-family: basicbold, Arial, sans-serif;
    font-weight: lighter;

    &:before {
      display: block;
      margin: 0 auto 20px;
    }
  }

  .stopper-modal__scroll__container__content {
    font-size: 1.7rem;
    text-align: center;
    color: $Bwhite;
    margin: 20px auto;
  }
}

.image-qr img {
  max-width: 250px;
}
