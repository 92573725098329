sbmn-flights-calendar {
  @extend sbmn-flights-results;
}

#fcalendar {
  display: flex;
  background-color: $Bgrey05;
}

#fcalendar_list {
  width: 100%;
  padding: 0 3%;

  h1 {
    font-size: 4rem;
    margin: 25px 0 0;
    display: flex;
    align-items: center;
  }
}

#fcalendar_ads {
  width: 230px;
  @include mq('tablet', 'max') {
    display: none;
  }
}

.calendargrid {
  padding-bottom: 80px;
  padding-right: 20px;
  position: relative;
  @include mq('tablet', 'max') {
    padding-right: 0;
  }

  &__titulo1,
  &__titulo2 {
    font-family: basiclight, Arial, sans-serif;
    text-transform: uppercase;
    font-size: 2rem;
    text-align: left;
    color: $Bgrey6;
    @include mq('tablet', 'max') {
      display: none;
    }
  }

  &__titulo1 {
    border-bottom: 0;
  }

  &__titulo2 {
    position: absolute;
    right: -70px;
    transform: rotate(90deg);
    top: calc(50% - 20px);
    @include mq('tablet', 'max') {
      right: -60px;
    }
  }

  &__type1,
  &__type2,
  &__type3,
  &__type4,
  &__type5 {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    &:before {
      content: '';
      height: 100%;
      width: 8px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__type1 {
    &:before {
      background-color: $Bsecondary;
    }

    .calendargrid__price-maleta-container {
      color: black;
      background-color: rgba($Bsecondary, 0.4);

      &:hover {
        background-color: rgba($Bsecondary, 0.9);
      }
    }
  }

  &__type2 {
    &:before {
      background-color: $Bgreen;
    }

    .calendargrid__price-maleta-container {
      &:hover {
        background-color: $Bgreen;
        color: $Bwhite;

        .calendargrid__price-maleta-container {
          &__maleta {
            &__icon {
              &:before {
                color: $Bwhite;
              }
            }

            &__number {
              color: $Bwhite;
            }
          }
        }
      }
    }
  }

  &__type3 {
    &:before {
      background-color: $Bgreen-light;
    }

    .calendargrid__price-maleta-container {
      &:hover {
        background-color: $Bgreen-light;
        color: $Bwhite;

        .calendargrid__price-maleta-container {
          &__maleta {
            &__icon {
              &:before {
                color: $Bwhite;
              }
            }

            &__number {
              color: $Bwhite;
            }
          }
        }
      }
    }
  }

  &__type4 {
    &:before {
      background-color: $Bthird-lighter;
    }

    .calendargrid__price-maleta-container {
      &:hover {
        background-color: $Bthird-lighter;
        color: $Bwhite;

        .calendargrid__price-maleta-container {
          &__maleta {
            &__icon {
              &:before {
                color: $Bwhite;
              }
            }

            &__number {
              color: $Bwhite;
            }
          }
        }
      }
    }
  }

  &__type5 {
    &:hover {
      background-color: $Bgrey5;
      color: $Bwhite;
    }
  }

  &__price-maleta-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    line-height: 24px;
    padding: 6px;
    min-height: 60px;
    flex-grow: 2;
    transition: background-color 0.2s linear;
    @include mq('tablet', 'max') {
      align-items: flex-end;
    }

    &__maleta {
      display: flex;
      align-items: center;

      &__to {
        background-color: $Bgrey3;
        border-radius: 50%;
        color: white;
        padding: 0px 5px;
        font-size: 10px;
        margin-right: 1em;
      }

      &__icon {
        border: none;
        position: relative;
        background-color: transparent;
        margin: 0;
        height: auto;
        min-height: unset;

        &:before {
          transition: color 0.2s linear;
          font-size: 20px;
          color: $Bgrey3;
        }
      }

      &__number {
        font-size: 0.8em;
        color: $Bgrey3;
        margin-left: 5px;
        position: relative;
        top: 2px;
        padding-top: 4px;
      }
    }
  }

  &__table {
    table-layout: fixed;
    width: 100%;
    text-align: center;
    border-spacing: 10px;

    &.table_responsive {
      overflow: visible;
      @include mq('tablet', 'min') {
        border-spacing: 10px;
        border-collapse: unset;
        width: 100%;
      }
    }

    @extend %table-tbody_responsive-st;

    tr {
      td {
        border: 1px solid $Bgrey5;
        height: auto;
        border-radius: 3px;
        font-size: 1.7rem;
        font-weight: bold;
        position: relative;
        color: $Bgrey6;
        background-color: $Bwhite;
        cursor: pointer;
        padding: 0;

        &:before {
          font-family: basiclight, Arial, sans-serif;
          font-size: 1.4rem;
        }
      }

      td:last-child {
        border: none;
        background-color: $Btransparent;
      }
    }

    @include mq('tablet', 'max') {
      tr td,
      td:first-child {
        width: 100%;
        padding: 0 !important;
        border-radius: 0;

        &:before {
          padding: 0 20px;
        }
      }
      tr td:last-child {
        span {
          display: none;
        }

        min-height: 20px;
        background-color: $Bblack;
      }
    }

    &__title-horiz,
    &__title-vert {
      font-size: 1.7rem;
      @include mq('tablet', 'max') {
        display: none;
      }

      &__number {
        font-weight: normal;
        margin-right: 5px;
        color: $Bgrey7;
      }

      &__month {
        font-weight: bold;
        text-transform: uppercase;
        color: $Bgrey7;
        font-size: 0.9em;
      }
    }

    &__title-vert {
      text-align: left;
      padding-left: 15px;
    }

    &__title-horiz {
      padding-bottom: 15px;
    }
  }

  &__legend {
    border-bottom: 2px dashed $Bgrey1;
    padding-top: 20px;
    text-align: center;
    padding-bottom: 5px;

    &__first,
    &__second,
    &__third,
    &__fourth,
    &__all {
      position: relative;
      cursor: pointer;
      color: $Bgrey7;
      font-size: 1.7rem;
      margin: 0 15px 10px;
      border-radius: 3px;
      padding: 3px 30px 5px 10px;
      border-bottom: 3px solid $Btransparent;
      display: inline-block;

      &:before {
        position: absolute;
        right: 5px;
        font-size: 1.2rem;
        transition: opacity 0.2s linear;
        opacity: 0;
      }

      &:hover,
      &.okselected {
        color: $Bblack;

        &:before {
          opacity: 1;
        }
      }

      @include mq('desktop', 'max') {
        font-size: 1.6rem;
        margin: 0 10px 10px;
      }
    }

    &__first {
      //&:hover, &.okselected{
      border-bottom-color: $Bsecondary;

      &:before {
        color: $Bsecondary;
      }

      //}
    }

    &__second {
      //&:hover, &.okselected{
      border-bottom-color: $Bgreen;

      &:before {
        color: $Bgreen;
      }

      //}
    }

    &__third {
      //&:hover, &.okselected{
      border-bottom-color: $Bgreen-light;

      &:before {
        color: $Bgreen-light;
      }

      //}
    }

    &__fourth {
      //&:hover, &.okselected{
      border-bottom-color: $Bthird-lighter;

      &:before {
        color: $Bthird-lighter;
      }

      //}
    }

    &__all {
      &:hover,
      &.okselected {
        border-bottom-color: $Bprimary-dark;

        &:before {
          color: $Bprimary-dark;
        }
      }
    }
  }
}

.-calendarDay-other,
.ND {
  opacity: 0.35;
  transition: none;

  &:hover,
  .calendargrid__price-maleta-container:hover {
    opacity: 1;
  }
}

.ND {
  &:before {
    display: none;
  }

  &:hover {
    opacity: 0.35;
    background: $Btransparent;

    .calendargrid__price-maleta-container {
      background: $Bwhite;
      color: $Bgrey6;
    }
  }

  .calendargrid__price-maleta-container {
    cursor: default;
  }
}

/*_____________________________________________ DETAIL LAYER ____*/

.csummary {
  display: block;
  position: absolute;
  border: 1px solid $Bgrey2;
  border-radius: 10px;
  background: $Bwhite;
  box-shadow: 0px 2px 7px 5px rgba($Bblack, 0.2);
  margin-bottom: 0;
  z-index: z(level3);
  width: 85%;
  max-width: 850px;
  overflow: hidden;
  @include mq('desktop', 'max') {
    max-width: 700px;
  }
  @include mq('tablet', 'max') {
    width: 100%;
  }

  &__content {
    display: flex;
    @include mq('tablet', 'max') {
      flex-direction: column;
    }

    &__ida-vuelta {
      width: 50%;
      padding: 15px;
      font-size: 1.4rem;

      &.solo-ida {
        width: 100%;
      }

      @include mq('tablet', 'max') {
        width: 100%;
      }

      &:nth-of-type(2) {
        border-left: 1px solid $Bgrey2;

        .csummary__content__ida-vuelta__block__icon:before {
          transform: rotate(180deg);
        }
      }

      &__icon {
        width: 100%;
        border-bottom: 1px solid $Bgrey2;
        padding-bottom: 5px;
        margin-bottom: 5px;
        font-family: basicbold, Arial, sans-serif;
        font-weight: lighter;
        color: $Bgrey6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;

        .ida {
          display: flex;
          align-items: center;

          &:before {
            @include circle(25px);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            margin-right: 10px;
            background: $Bgrey2;
          }
        }

        .icon1:before {
          background: $Bsecondary;
          color: $Bblack;
        }

        .icon2:before {
          background: $Bgreen;
          color: $Bwhite;
        }

        .icon3:before {
          background: $Bgreen-light;
          color: $Bwhite;
        }

        .icon4:before {
          background: $Bprimary;
          color: $Bwhite;
        }

        &__duration {
          background: $Bgrey1;
          color: $Bgrey8;
          padding: 4px 10px;
          border-radius: 50px;
          font-size: 0.9em;
          min-width: 91px;
          text-align: center;
        }
      }

      &__jump {
        margin: 10px 0;
        background-color: $Bgrey05;
        padding: 4px 10px;
        border-radius: 3px;
        text-align: center;
        @include mq('tablet', 'max') {
          margin-bottom: 15px;
          padding: 4px 5px;
        }
      }

      &__block {
        display: flex;
        justify-content: space-between;
        text-align: left;
        color: $Bgrey6;
        flex-wrap: wrap;

        &__left {
          flex-grow: 2;
          width: calc(100% - 91px);

          &__airline {
            margin-bottom: 10px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            font-size: 1.2rem;

            img {
              position: relative;
              top: 5px;
              margin: 0 5px;
            }
          }

          &__flight {
            display: flex;
            justify-content: space-between;

            strong {
              padding-right: 5px;
            }
          }
        }

        &__right {
          text-align: right;

          &__duration {
            background: $Bgrey1;
            color: $Bgrey6;
            padding: 4px 10px;
            border-radius: 50px;
            font-size: 0.9em;
            min-width: 91px;
            text-align: center;
          }
        }
      }

      &__block__icons,
      &__icons {
        display: flex;
        margin: 6px 0 0;

        &__item {
          border: 0;
          width: auto;
          height: auto;
          margin-right: 10px;
          min-height: 0;
          font-size: 1.2rem;

          &:before {
            font-size: 1.2rem;
            margin-right: 2px;
          }
          &.maleta:before {
            margin: -5px 5px 0 0;
          }
        }
      }
    }
  }

  &[class*='r0'] {
    bottom: auto;
    top: 165px;
  }

  &[class*='r1'] {
    bottom: auto;
    top: 240px;
  }

  &[class*='r2'] {
    bottom: auto;
    top: 315px;
  }

  &[class*='r3'] {
    bottom: 394px;
    top: auto;
  }

  &[class*='r4'] {
    bottom: 318px;
    top: auto;
  }

  &[class*='r5'] {
    bottom: 241px;
    top: auto;
  }

  &[class*='r6'] {
    bottom: 165px;
    top: auto;
  }

  &[class*='c0'] {
    left: 0;
    right: auto;
  }

  &[class*='c1'] {
    left: 12%;
    right: auto;
  }

  &[class*='c2'] {
    left: 20%;
    right: auto;
  }

  &[class*='c3'] {
    left: 25%;
    right: auto;
  }

  &[class*='c4'] {
    left: auto;
    right: 18%;
  }

  &[class*='c5'] {
    left: auto;
    right: 12%;
  }

  &[class*='c6'] {
    left: auto;
    right: 6%;
  }

  /*&[class*='c0'],
&[class*='c1'],
&[class*='c2'],
&[class*='c3']{
  left:0;
  right:auto;
}
&[class*='c4'],
&[class*='c5'],
&[class*='c6'],
&[class*='c7']{
  left:auto;
  right:0;
}
&[class*='r0'],
&[class*='r1'],
&[class*='r2'],
&[class*='r3']{
  top:80px;
  bottom:auto;
}
&[class*='r4'],
&[class*='r5'],
&[class*='r6'],
&[class*='r7']{
  top:auto;
  bottom:80px;
}*/
}
