@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@mixin font-face($font-name, $file-name) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.woff2') format('woff2');
  }
}

@include font-face('basicbold', '/assets/fonts/MrEavesXLModOT-Heavy');
@include font-face('basiclight', '/assets/fonts/MrEavesXLModOT-Light');
@include font-face('basicregular', '/assets/fonts/MrEavesXLModOT-Reg');
@include font-face('special', '/assets/fonts/Calibri');
@font-face {
  font-family: icons;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/icons.woff') format('woff');
}

.ico__ {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arrow_next:before {
  @extend .ico__;
  content: '\e901';
}
.avion:before {
  @extend .ico__;
  content: '\e902';
}
.ida:before {
  @extend .ico__;
  content: '\e903';
}
.billete:before {
  @extend .ico__;
  content: '\e904';
}
.butaca:before {
  @extend .ico__;
  content: '\e905';
}
.calendar:before {
  @extend .ico__;
  content: '\e906';
}
.clock:before {
  @extend .ico__;
  content: '\e907';
}
.editar:before {
  @extend .ico__;
  content: '\e908';
}
.escala:before {
  @extend .ico__;
  content: '\e909';
}
.filtrar:before {
  @extend .ico__;
  content: '\e90a';
}
.gear:before {
  @extend .ico__;
  content: '\e90b';
}
.document:before {
  @extend .ico__;
  content: '\e90c';
}
.idavuelta:before {
  @extend .ico__;
  content: '\e90d';
}
.soloida:before {
  @extend .ico__;
  content: '\e90e';
}
.info3:before {
  @extend .ico__;
  content: '\e90f';
}
.ko:before {
  @extend .ico__;
  content: '\e910';
}
.localizador:before {
  @extend .ico__;
  content: '\e911';
}
.maleta:before {
  @extend .ico__;
  content: '\e912';
}
.ok:before {
  @extend .ico__;
  content: '\e913';
}
.pasajero:before {
  @extend .ico__;
  content: '\e914';
}
.pasajerobuscador:before {
  @extend .ico__;
  content: '\e915';
}
.upload:before {
  @extend .ico__;
  content: '\e916';
}
.money:before {
  @extend .ico__;
  content: '\e917';
}
.seguro:before {
  @extend .ico__;
  content: '\e918';
}
.menosopciones:before {
  @extend .ico__;
  content: '\e919';
}
.multitrayecto:before {
  @extend .ico__;
  content: '\e91a';
}
.pin:before {
  @extend .ico__;
  content: '\e91b';
}
.poweroff:before {
  @extend .ico__;
  content: '\e91c';
}
.end:before {
  @extend .ico__;
  content: '\e91d';
}
.personas:before {
  @extend .ico__;
  content: '\e91e';
}
.credit_card:before {
  @extend .ico__;
  content: '\e91f';
}
.agency_data:before {
  @extend .ico__;
  content: '\e920';
}
.cash:before {
  @extend .ico__;
  content: '\e921';
}
.warning:before {
  @extend .ico__;
  content: '\e922';
}
.pdf:before {
  @extend .ico__;
  content: '\e923';
}
.recycle:before {
  @extend .ico__;
  content: '\e924';
}
.msg-general:before {
  @extend .ico__;
  content: '\e925';
}
.msg-warnings:before {
  @extend .ico__;
  content: '\e926';
}
.msg-flights:before {
  @extend .ico__;
  content: '\e927';
}
.bono:before {
  @extend .ico__;
  content: '\e928';
}
.plus:before {
  @extend .ico__;
  content: '\e929';
}
.less:before {
  @extend .ico__;
  content: '\e92a';
}
.conversacion:before {
  @extend .ico__;
  content: '\e92b';
}
.msg-leido:before {
  @extend .ico__;
  content: '\e92c';
}
.can:before {
  @extend .ico__;
  content: '\e92d';
}
.mail:before {
  @extend .ico__;
  content: '\e92e';
}
.phone:before {
  @extend .ico__;
  content: '\e92f';
}
.ayuda:before {
  @extend .ico__;
  content: '\e930';
}
.split:before {
  @extend .ico__;
  content: '\e931';
}
.megafono:before {
  @extend .ico__;
  content: '\e932';
}
.trolley:before {
  @extend .ico__;
  content: '\e933';
}
.play:before {
  @extend .ico__;
  content: '\e934';
}
.videoconf:before {
  @extend .ico__;
  content: '\e935';
}
.webcam:before {
  @extend .ico__;
  content: '\e936';
}
.waiting:before {
  @extend .ico__;
  content: '\e937';
}
.office:before {
  @extend .ico__;
  content: '\e938';
}
.refund:before {
  @extend .ico__;
  content: '\e939';
}
.pdf-insurance:before {
  @extend .ico__;
  content: '\e93a';
}
.qr_code:before {
  @extend .ico__;
  content: '\e900';
}

/*----- extra -----*/

.msg-admin:before {
  @extend .ico__;
  content: '\e938';
}

.msg-refunds:before {
  @extend .ico__;
  content: '\e939';
}

.msg-web:before {
  @extend .ico__;
  content: '\e932';
}

.delete:before {
  @extend .ico__;
  content: '\e910';
}

.vuelta {
  @extend .ida;
  transform: rotate(180deg);
}
