#login-container {
  max-height: 456px;
  overflow: hidden;
  min-width: 500px;
  width: 45%;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  @include mq('tablet', 'max') {
    width: 100%;
    min-width: 100%;
    max-height: 550px;
  }

  .Berror {
    position: relative;
    font-size: 1.5rem;
    bottom: 0;
    margin-bottom: 10px;
    @include mq('tablet', 'max') {
      display: flex;
      justify-content: center;
    }
  }

  re-captcha {
    @include mq('tablet', 'max') {
      display: flex;
      justify-content: center;
    }
  }
}

#login-access,
#login-change-password,
#login-submit-password {
  position: relative;
  padding: 0 20px;
  min-height: 400px;
  font-size: 1.6rem;
  opacity: 1;
  transition:
    opacity 1s linear,
    top 0.2s linear;
  @include mq('tablet', 'max') {
    padding: 20px 20px;
    min-height: 550px;
  }
  @include mq('phone-wide', 'max') {
    padding: 20px;
  }

  h1 {
    margin-bottom: 20px;
    text-align: center;
    @include mq('phone-wide', 'max') {
      font-size: 4rem;
    }
  }

  button {
    margin: 10px 0 20px 0;
    float: right;
  }
}

#login-access button {
  @include mq('tablet', 'max') {
    float: none;
  }
}

#login-change-password {
  h1.h1withbold {
    font-size: 4rem;
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 20px;
  }

  .Berror {
    position: relative;
    font-size: 1.5rem;
    bottom: 0;
    @include mq('tablet', 'max') {
      display: flex;
      justify-content: center;
    }
  }
}

.login-reset-active {
  top: -400px;
  @include mq('tablet', 'max') {
    top: -540px;
  }

  &#login-access {
    opacity: 0;
  }
}

.login-change-active {
  top: -800px;
  @include mq('tablet', 'max') {
    top: -1100px;
  }

  &#login-access {
    opacity: 0;
  }
}

.login-reset-inactive {
  top: 0;

  &#login-submit-password,
  &#login-change-password {
    opacity: 0;
  }
}

.login-reset-nav {
  position: relative;
  top: 27px;
  @extend %Blink;
  @include mq('tablet', 'max') {
    display: block;
    top: 0;
    margin: 20px 0;
  }
}

.login-reset-password {
  @include mq('tablet', 'max') {
    text-align: center;
  }
}

.login__loading {
  @extend .loader_abs;
  opacity: 0;
}

#login-new-account {
  position: absolute;
  color: $Bgrey5;
  padding: 10px;
  margin: 0 auto;
  font-size: 1.6rem;
  text-align: center;
  clear: both;
  bottom: 0;
  width: 100%;
  @include mq('tablet', 'max') {
    line-height: 1.4;
  }

  a {
    @include mq('phone-wide', 'max') {
      display: block;
    }
  }
}
