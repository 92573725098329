sbmn-access {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  //animation: backMove 10s linear infinite 1s;
  color: $Bgrey6;
  padding: 3%;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  &:before {
    content: '';
    position: fixed;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      76deg,
      $Bprimary-dark-gradient1 10%,
      $Bwhite 45%,
      $Bwhite 60%,
      $Bprimary-dark-gradient1 90%
    );
    background-size: 100% 100%;
    opacity: 0.8;
    top: 0;
  }

  > header,
  figure,
  > section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96%;
    z-index: z(level1);
  }

  > header {
    flex-direction: column;
    margin-bottom: 2vh;
    z-index: z(floor);
    max-width: 450px;
    span {
      font-size: 1.6rem;
      color: $Bgrey5;
    }
  }

  figure {
    height: 98%;

    img {
      max-width: 800px;
      min-width: 200px;
      width: 80%;
    }
  }

  sbmn-lang-selector {
    position: absolute;
    right: 10px;
    top: 10px;
    &:hover #langTrigger {
      color: $Bsecondary;
      border-color: $Bsecondary;
    }
  }
}
